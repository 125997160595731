import React from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Col } from 'react-bootstrap';

import '../assets/scss/main.scss';

export default function Home() {
  return  (
    <Container className="wrapper">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Jana Gluchman Michalcová ❋ Tour Guide</title>
        <meta name="description" content="I'm Jana Michalcová and I am the Professional Tour Guide of Prague and the Czech Republic." />
        <link rel="canonical" href="https://www.michalcova.cz/" />
        <html lang="en" />
        <meta property='og:title' content='Jana Gluchman Michalcová ❋ Personal Tour Guide of Prague' />
        <meta property="og:image" content="https://www.michalcova.cz/images/background.jpg" />
        <meta property='og:url' content='https://www.michalcova.cz/' />
        <meta property='og:type' content='website' />
      </Helmet>
      <Row>
        <Col md={6} sm={12} className="offset-lg-6 offset-md-4">
          <Row className="vcard">
            <Col sm={12} className="header">
                <h1>
                  {/* <small>Hello, I’m</small>  */}
                  <span className="fn">Jana Gluchman Michalcová</span>
                </h1>

                <h2>
                  I am a professional certified personal <span className="category">tour 
                  guide</span> of <span className="adr"><span className="locality">Prague</span> and the <span
                  className="country-name">Czech Republic</span></span>
                </h2>

            </Col>

            <Col sm={12} className="contacts">
                <h3>
                    <a href="mailto:jana@michalcova.cz" className="email">jana@michalcova.cz</a>
                </h3>

                <h3 className="tel" title="+420604975547">
                  <a href="tel:+420604975547">(+420) 604 975 547</a>
                </h3>
            </Col>
          </Row>
          <Row className="social">
            <Col sm={12}>
              <span className="social__icon social__icon-fb">
                  <a href="https://www.facebook.com/anchjm" target="_blank" rel="noopener" title="Find me on Facebook">Find me on Facebook</a>
              </span>
              <span className="social__icon social__icon-ig">
                  <a href="https://www.instagram.com/janamichalcova/" target="_blank" rel="noopener" title="Find me on Instagram">Find me on Instagram</a>
              </span>
              <span className="social__icon social__icon-li">
                  <a href="https://www.linkedin.com/in/jana-gluchman-michalcova/" target="_blank" rel="noopener" title="Find me on LinkedIn">Find me on LinkedIn</a>
              </span>
            </Col>
          </Row>

          <Row className="content">
            <Col>
              <p>See my featured projects:</p>
              <div className="projects">
                <a href="https://www.praguejourneys.com" target="_blank" rel="noopener" title="Prague Journeys tours">
                  <Row className="project">
                    <Col className="project__logo" sm={5}>
                      <img src="/images/logo-praguejourneys.png" alt="Prague Journeys logo" />
                    </Col>
                    <Col sm={7} className="mb-2">
                      <h3>Prague Journeys</h3>
                      <p>
                        My private tour guide web site and a weblog with tips on what to see
                        in Prague and the Czech Republic.
                      </p>
                    </Col>
                  </Row>
                </a>
                <a href="https://www.memorialtour.cz" target="_blank" rel="noopener" title="Prague WWII Memorial Tour">
                  <Row className="project">
                    <Col className="project__logo" sm={5}>
                      <img src="/images/logo-memorialtour.png" alt="Prague WWII Memorial Tour" />
                    </Col>
                    <Col sm={7} className="mb-2">
                      <h3>Prague WWII Memorial Tour</h3>
                      <p>
                        A special tour to uncover the stories of WWII in and around Prague, 
                        focused on Operation Anthropoid and the nazi resistance.
                      </p>
                    </Col>
                  </Row>
                </a>
              </div>
            </Col>
            {/* <Col>
              <p>My work is a tour guide. Most of the time I walk through the streets 
                  of Prague and visit other cities all over the Czech Republic, 
                  with the travelers who speak English.</p>
              <p>I'm certified by the licence of the city of Prague, Jewish Town and the Czech 
                  Republic.</p>
              <p>I love to travel, discover new cultures and see the sights where the history 
                  happened. Relatively still small amount of those places I visited, however, there’s the 
                  rest of the world in my bucket list.</p>
              <p>I also love hiking, reading, listening to music and from time to time scuba 
                  diving... just because the silence in deep water is magnificent :-)</p>
            </Col> */}
          </Row>

        </Col>
      </Row>

    </Container>
  );
}
